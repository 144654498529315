import React, { useEffect, useState } from "react";
import { HeadingTitle } from "../../Modal/Modal";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import { SaveDockActions } from "../../../Actions/OrderHandoverActions/OrderHandoverActions";
import Loader from "../../Loader/Loader";

const CreateDock = (props) => {
  const alert = useAlert();
  let isAuthenticated = JSON.parse(localStorage.getItem("user"));
  let token = isAuthenticated && isAuthenticated?.token;
  const dispatch = useDispatch();
  const { close, handleRefresh } = props;

  const closeModal = useCallback(() => {
    close(null);
  }, [close]);

  const { save__dock, loading } = useSelector((state) => state.saveDock);

  const [name, setName] = useState("");

  const [isSaved, setIsSaved] = useState(false);

  const handleSaveDock = useCallback(
    (e) => {
      e.preventDefault();
      setIsSaved(true);
      dispatch(SaveDockActions("add_dock", name, token));
    },
    [dispatch, name, token]
  );

  useEffect(() => {
    if (save__dock && save__dock?.status === 1 && isSaved) {
      alert.success(
        (save__dock && save__dock?.message) || "Dock Created Successfully"
      );
      setIsSaved(false);
      closeModal();
      handleRefresh();
    }
    if (save__dock && save__dock?.status === 0 && isSaved) {
      alert.error(save__dock && save__dock?.msg);
      setIsSaved(false);
    }
    // eslint-disable-next-line
  }, [save__dock, isSaved, alert]);

  return (
    <>
      <HeadingTitle title="Create Dock" closeModal={close} />
      <form onSubmit={handleSaveDock} className="create__dock__container">
        <input
          type="text"
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Button type="submit">{loading ? <Loader /> : "Create Dock"}</Button>
      </form>
    </>
  );
};

export default CreateDock;
